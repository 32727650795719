/**
 * Main Class
 *
 * @class Main
 * @constructor
 * @namespace core
 */
CDQ.core.Main = Class.extend({
	init: function() {
		this.setup(); 
		
	},

	setup: function() {
        if(CDQ.global.util.getViewportSize().width>=768) {
            new CDQ.component.Collapser();
        }
        if(CDQ.global.util.isMobile()) {
            new CDQ.component.ImageSwitchTouch();
        }else {
            new CDQ.component.ImageSwitchHover();
        }


        new CDQ.component.MobileNav();
	}
});

CDQ.global.util = new CDQ.core.Util();


(function() {
	new CDQ.core.Main();
   
})();
