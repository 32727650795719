/**
 * FAQ: Toggler
 *
 * @class Faq
 * @constructor
 * @namespace component
 */
 CDQ.component.ImageSwitchTouch = Class.extend({
    /**
     * init
     *
     * @method init
     /* initializes Filter class and clickevents to show/hide the layer
     */
    init: function(settings) {
         console.info('muu')
        $('.pic02-mobile').on('click', function(){
            console.info('meep', $(this).prev())
            $('.pic01-mobile').addClass('opaque');
            $(this).removeClass('opaque');
            $('.text02-mobile').addClass('in');
            $('.text01-mobile').removeClass('in');
        })

        $('.pic01-mobile').on('click', function(){
            console.info('meep', $(this).prev())
            $('.pic02-mobile').addClass('opaque');
            $(this).removeClass('opaque');
            $('.text01-mobile').addClass('in');
            $('.text02-mobile').removeClass('in');
        })
        
    }
});

