/**
 * FAQ: Toggler
 *
 * @class Faq
 * @constructor
 * @namespace component
 */
 CDQ.component.ImageSwitchHover = Class.extend({
    /**
     * init
     *
     * @method init
     /* initializes Filter class and clickevents to show/hide the layer
     */
    init: function(settings) {
        $('.pic02').addClass('opaque');
        $('.pic02').on('mouseenter', function(){
            $('.pic01').addClass('opaque');
            $(this).removeClass('opaque');
            $('.text02').addClass('in');
        })

        $('.pic02').on('mouseleave', function(){
            $('.pic01').removeClass('opaque');
            $(this).addClass('opaque');
            $('.text02').removeClass('in');
        })

        $('.pic01').on('mouseenter', function(){
            $('.pic02').addClass('opaque');
            $(this).removeClass('opaque');
            $('.text01').addClass('in');
        })

        $('.pic01').on('mouseleave', function(){
            $('.pic02').removeClass('opaque');
            $(this).addClass('opaque');
            $('.text01').removeClass('in');
        })
        
    }
});

