/**
 * FAQ: Toggler
 *
 * @class Faq
 * @constructor
 * @namespace component
 */
 CDQ.component.Collapser = Class.extend({
    /**
     * init
     *
     * @method init
     /* initializes Filter class and clickevents to show/hide the layer
     */
    init: function(settings) {
        
        $('.panel-collapse').each(function(index){
            $(this).removeClass('collapse').addClass('fade');
            $(this).appendTo(".target");
            if(index === 0){
                $(this).addClass('in')
            }
        })

        $('.panel-title').each(function(index){
            if($(this).find('a').attr('href') === location.hash){
                $('.panel-title a').removeClass('active')
                $(this).find('a').addClass('active');
                $('.panel-collapse').removeClass('in')
                var el = $('.panel-collapse')[index]
                $(el).addClass('in')
            }
            $(this).on('click', function(){
                $('.panel-title a').removeClass('active')
                $(this).find('a').addClass('active');
                $('.panel-collapse').removeClass('in')
                var el = $('.panel-collapse')[index]
                $(el).addClass('in')
            })
        })
        
    }
});

