/**
 * FAQ: Toggler
 *
 * @class Faq
 * @constructor
 * @namespace component
 */
 CDQ.component.MobileNav = Class.extend({
    /**
     * init
     *
     * @method init
     /* initializes Filter class and clickevents to show/hide the layer
     */
    init: function(settings) {
        $('.contact-menu').on('click', function(ev){
            event.preventDefault();
            $('.mobile-contact').addClass('open')
        })

        $('.close-contact-menu').on('click', function(ev){
            event.preventDefault();
            $('.mobile-contact').removeClass('open')
        })
        
    }
});

