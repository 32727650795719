/**
 * Util class
 *
 * @class Util
 * @constructor
 * @namespace core
 */
CDQ.core.Util = Class.extend({
	/**
	 * Util constructor
	 *
	 * @method init
	 */
	init: function() {
		this.checkOperationalSystem();
	},

	/**
	 * Execute some modernizer tests to add some classes related to operational systems in order to apply specific CSS dor it.
	 *
	 * @method checkOperationalSystem
	 */
	checkOperationalSystem: function() {
		/*Modernizr.addTest('macintosh', function() {
			return !!navigator.userAgent.match(/Macintosh/i);
		});

		Modernizr.addTest('windows', function() {
			return !!navigator.userAgent.match(/windows/i);
		});

		Modernizr.addTest('linux', function() {
			return !!navigator.userAgent.match(/linux/i);
		});
        Modernizr.addTest('ios', function() {
            return !!navigator.userAgent.match(/iPad/i) || !!navigator.userAgent.match(/iPhone/i);
        });
        Modernizr.addTest('ipad', function() {
            return !!navigator.userAgent.match(/iPad/i);
        });*/
        Modernizr.addTest('mobile', function() {
            return !!navigator.userAgent.match(/Mobile/i);
        });
	},
	
	/**
	 * Check if browser is IE less than 9.
	 *
	 * @method checkIE8
	 * @return {Boolean} True for ie8
	 */
	checkIE8: function() {
		return jQuery('html').hasClass('lt-ie9') ? true : false;
	},

	/**
	 * Check if device is touch
	 *
	 * @method isTouch
	 * @return {Boolean} True for touch devices
	 */
	isTouch: function() {
		if (Modernizr.touch === true) {
			return true;
		}

		return false;
	},

	/**
	 * Check if device is mobile
	 *
	 * @method isMobile
	 * @return {Boolean} True for mobile device
	 */
	isMobile: function() {
		if (window.navigator.userAgent.match('Mobile')) {
			return true;
		}

		return false;
	},

	/**
	 * Check if device OS is iOS
	 *
	 * @method isIOS
	 * @return {Boolean} True if iOS
	 */
	isIOS: function() {
		return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
	},

	/**
	 * Get view port size
	 *
	 * @method getViewportSize
	 * @param  {Object} w Window object
	 * @return {Object} View port width and height
	 */
	getViewportSize: function(w) {
		w = w || window;

		var d = w.document;

		if (w.innerWidth != null) return {
			width: w.innerWidth,
			height: w.innerHeight
		};

		if (document.compatMode == "CSS1Compat") {
			return {
				width: d.documentElement.clientWidth,
				height: d.documentElement.clientHeight
			};
		}

		return {
			width: d.body.clientWidth,
			height: d.body.clientHeight
		};
	},

	/**
	 * Get breakpoint widths
	 *
	 * @method getBreakpoints
	 * @param  {String} breakpoint Breakpoint name
	 * @return {String|Object} If breakpoint is defined, return just breakpoint 
	 * width, otherwise returns all breakpoints as an object
	 */
	getBreakpoints: function(breakpoint) {
		var breakpoints = {
			small: 480,
			medium: 600,
			largeIntermediate: 680,
			large: 768
		};

		if (breakpoint) {
			return breakpoints[breakpoint];
		}

		return breakpoints;
	},

	/**
	 * Set tappable for touch devices
	 *
	 * @method tappable
	 * @param {HTMLElement} element Element to apply event
	 * @param {Function} callback Callback to fire on tap
	 * @param  {Integer} delay Delay to define it is a tap
	 */
	tappable: function(element, callback, delay) {
		var touchDelay = delay || 150;
		return element.tappable({
			callback: callback,
			touchDelay: touchDelay
		});
	},

	/**
	 * Format a number to add decimals
	 *
	 * @method numberFormat
	 * @param {Integer} n Number to format
	 * @param {Integer} d Decimals to add
	 * @return {String} Formatted number
	 */
	numberFormat: function(n, d) {
		var decimals = d || 0;
		return n.toFixed(decimals);
	},

	/**
	 * Check if the current url is valid or not
	 *
	 * @method isValidUrl
	 * @param  {String} url Url to validate
	 * @return {Boolean} True for valid url
	 */
	isValidUrl: function(url) {
		var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

		if (url && url.match(pattern)) {
			return true;
		}

		ce('## CDQ.core.Util.isValidUrl => Error: Invalid url');

		return false;
	},

	/**
	 * Slugify a String
	 *
	 * @method slugify
	 * @param  {String} string String to slugify
	 * @return {String} Slugified string
	 */
	slugify: function(string) {
		return string.replace(' ', '-').toLowerCase();
	},

    /**
     * getURLParam 
     *
     * @method getURLParam
     * @param  {String} string String to getURLParam
     * @return {String} Slugified string
     */
    getURLParam: function(sParam) {
        

        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) 
        {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) 
            {
                return sParameterName[1];
            }
        }

    },
    /**
     * getURLParam 
     *
     * @method getURLParam
     * @param  {String} string String to getURLParam
     * @return {String} Slugified string
     */
    getURLAnker: function() {
        var sPageURL = window.location.hash ;
        var sURLHashname = sPageURL.split('#');
        return sURLHashname[1];

    }
});